/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

/* global URL, _VALOTA_LOG_CHROME_OS, _displayID, _VALOTA_REST, _applicationData */

// TODO enable parallelism
// TODO memory checks

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}

ValotaEngine.ChromeOS = {
	inited: false,
	PIN: null,
	callback: null,
	cacheQueue: [],
	files: {},
	videoOwners: [],
	screenshotCallback: false,
	appOrigin: null,
	overlayPlaying: false,
	emergencyActive: false,
	overlayData: null,
	start: function () {
		console.warn("[Engine ChromeOS] start");
		if (!ValotaEngine.ChromeOS.inited) {
			window.addEventListener('message', ValotaEngine.ChromeOS.onMessage);
			ValotaEngine.ChromeOS.inited = true;
			window.setTimeout(function () {
				if (typeof ValotaEngine.ChromeOS.appWindow === 'undefined' || !ValotaEngine.ChromeOS.appWindow) {
					logError("no contact to ChromeApp", ValotaEngine.ChromeOS.not_defined, "warning");
				}
			}, 10 * 60 * 1000);
		}
	},
	clearUUID: function () {
		if (!ValotaEngine.ChromeOS.appOrigin) {
			console.error("[Engine ChromeOS] clear UUID but no contact to ChromeApp");
		}
		ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "clear_uuid"}, ValotaEngine.ChromeOS.appOrigin);
	},
	saveUUID: function (uuid) {
		console.warn("[Engine ChromeOS] save uuid", uuid);
		if (!ValotaEngine.ChromeOS.appOrigin) {
			console.error("[Engine ChromeOS] save UUID but no contact to ChromeApp");
		}
		ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "save_uuid", uuid: uuid}, ValotaEngine.ChromeOS.appOrigin);
	},
	getScreenshot: function (callback) {
		ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "get_screenshot"}, ValotaEngine.ChromeOS.appOrigin);
		ValotaEngine.ChromeOS.screenshotCallback = callback;
	},
	playOverlayApp: function (appData) {
		if (typeof ValotaEngine.ChromeOS.appWindow === 'undefined') {
			window.setTimeout(function () {
				ValotaEngine.ChromeOS.playOverlayApp(appData);
			}, 100);
			return;
		}
		ValotaEngine.ChromeOS.overlayData = appData;
		if (!ValotaEngine.ChromeOS.emergencyActive) {
			ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "overlay_new_app", appData: appData}, ValotaEngine.ChromeOS.appOrigin);
			ValotaEngine.ChromeOS.overlayPlaying = true;
		}
	},
	emergency: function () {
		if (!ValotaEngine.ChromeOS.emergencyActive) {
			console.log("[Engine ChromeOS] overlay emergency starts, currently " + (ValotaEngine.ChromeOS.overlayPlaying ? "playing" : "stopped"));
			if (ValotaEngine.ChromeOS.overlayPlaying) {
				ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "overlay_stop"}, ValotaEngine.ChromeOS.appOrigin);
				ValotaEngine.ChromeOS.overlayPlaying = false;
			}
			ValotaEngine.ChromeOS.emergencyActive = true;
		}
	},
	emergencyOver: function () {
		if (ValotaEngine.ChromeOS.emergencyActive) {
			console.log("[Engine ChromeOS] overlay emergency over, " + (ValotaEngine.ChromeOS.overlayData === null ? "will do nothing" : "will start"));
			if ((!ValotaEngine.ChromeOS.overlayPlaying) && ValotaEngine.ChromeOS.overlayData !== null) {
				ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "overlay_new_app", appData: ValotaEngine.ChromeOS.overlayData}, ValotaEngine.ChromeOS.appOrigin);
				ValotaEngine.ChromeOS.overlayPlaying = true;
			}
			ValotaEngine.ChromeOS.emergencyActive = false;
		}
	},
	overlayAppChangedCustoms: function (customs) {
		if (ValotaEngine.ChromeOS.overlayData !== null) {
			ValotaEngine.ChromeOS.overlayData.customs = customs;
			if (ValotaEngine.ChromeOS.overlayPlaying) {
				ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "overlay_new_customs", customs: customs}, ValotaEngine.ChromeOS.appOrigin);
			}
		}
	},
	overlayAppChangedPalette: function (palette) {
		if (ValotaEngine.ChromeOS.overlayData !== null) {
			ValotaEngine.ChromeOS.overlayData.palette = palette;
			if (ValotaEngine.ChromeOS.overlayPlaying) {
				ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "overlay_new_palette", palette: palette}, ValotaEngine.ChromeOS.appOrigin);
			}
		}
	},
	overlayAppChangedSource: function (source) {
		if (ValotaEngine.ChromeOS.overlayData !== null) {
			ValotaEngine.ChromeOS.overlayData.source = source;
			if (ValotaEngine.ChromeOS.overlayPlaying) {
				ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "overlay_new_source", source: source}, ValotaEngine.ChromeOS.appOrigin);
			}
		}
	},
	stopOverlayApp: function () {
		ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "overlay_stop"}, ValotaEngine.ChromeOS.appOrigin);
		ValotaEngine.ChromeOS.overlayPlaying = false;
		ValotaEngine.ChromeOS.overlayData = null;
	},
	reloadApp: function () {
		ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "reload"}, ValotaEngine.ChromeOS.appOrigin);
	},
	onMessage: function (e) {
		if (window.location.href === 'https://device.valotalive.com/' &&
			e.origin.indexOf("//eobampdmalpepccadnpeldkoabnbfeng") === -1) {
			console.warn("[Engine ChromeOS] unknown origin " + e.origin);
			return;
		}
		if (typeof e.data !== "object" ||
			!e.data.hasOwnProperty("cmd")) {
			console.warn("[Engine ChromeOS] syntax error");
			return;
		}
		switch (e.data.cmd) {
			case "initial":
				if (document.getElementById('enter_pin').style.display !== 'none' && e.data.PIN && e.data.PIN.length === 8) {
					document.getElementById('input_pin').value = e.data.PIN;
					ValotaEngine.ChromeOS.PIN = e.data.PIN;
					document.getElementById('pin_btn').disabled = false;
					document.getElementById('pin_btn').click();
				}
				if (e.data.displayUUID && _displayID !== e.data.displayUUID) {
					// got a different displayUUID from the app than what we are using, switching
					logError("switching displayUUID like ChromeApp dictates, new [" + e.data.displayUUID + "]", ValotaEngine.ChromeOS.not_defined, "warning");

					_displayID = e.data.displayUUID;
					setLocal('display', _displayID);
					window.setTimeout(function () {
						console.log("reloading");
						reloadDisplay(false);
					}, 10000);
				}
				console.log("[Engine ChromeOS] initial uuid", e.data.displayUUID);
				ValotaEngine.ChromeOS.appWindow = e.source;
				ValotaEngine.ChromeOS.appOrigin = e.origin;
				if (e.data.displayUUID === null) {
					ValotaEngine.ChromeOS.saveUUID(_displayID);
				}
				ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "get_logs"}, ValotaEngine.ChromeOS.appOrigin);
				var cacheRetrievalObj = ValotaEngine.ChromeOS.cacheQueue.shift();
				if (typeof cacheRetrievalObj !== "undefined") {
					ValotaEngine.ChromeOS.cacheFile(cacheRetrievalObj.name, cacheRetrievalObj.url, cacheRetrievalObj.callback);
				}
				break;
			case "device_id":
				console.log("[Engine ChromeOS] device id", e.data.deviceID);
				sendMyDeviceID(e.data.deviceID);
				break;
			case "bt_address":
				console.log("[Engine ChromeOS] BT address", e.data.btAddress);
				sendMyBTAddress(e.data.btAddress);
				break;
			case "loaded":
				if (e.data.filename !== ValotaEngine.ChromeOS.name ||
					e.data.url !== ValotaEngine.ChromeOS.url) {
					console.error("[Engine ChromeOS] bad coding", e.data);
					if (ValotaEngine.ChromeOS.callback !== null) {
						ValotaEngine.ChromeOS.callback(e.data.filename, e.data.url, '', {status: "error", message: _('Bad coding')});
					}
					ValotaEngine.ChromeOS.callback = null;
					ValotaEngine.ChromeOS.name = null;
					ValotaEngine.ChromeOS.url = null;
					return;
				}
				if (ValotaEngine.ChromeOS.callback !== null) {
					ValotaEngine.ChromeOS.callback(e.data.filename, e.data.url, '', {status: 'ok'});
				}
				ValotaEngine.ChromeOS.callback = null;
				ValotaEngine.ChromeOS.name = null;
				ValotaEngine.ChromeOS.url = null;
				ValotaEngine.ChromeOS.files[e.data.filename].loading = false;
				var cacheRetrievalObj = ValotaEngine.ChromeOS.cacheQueue.shift();
				if (typeof cacheRetrievalObj !== "undefined") {
					ValotaEngine.ChromeOS.cacheFile(cacheRetrievalObj.name, cacheRetrievalObj.url, cacheRetrievalObj.callback);
				}
				break;
			case "error":
				console.log("[Engine ChromeOS] error message received", e.data);
				if (ValotaEngine.ChromeOS.callback !== null) {
					ValotaEngine.ChromeOS.callback(e.data.filename, e.data.url, '', {status: "error", message: _("filesystem error: " + e.data.message)});
					console.error("[Engine ChromeOS] file load error", e.data.message, e.data.reason);
				}
				ValotaEngine.ChromeOS.callback = null;
				ValotaEngine.ChromeOS.name = null;
				ValotaEngine.ChromeOS.url = null;
				ValotaEngine.ChromeOS.files[e.data.filename].loading = false;
				var cacheRetrievalObj = ValotaEngine.ChromeOS.cacheQueue.shift();
				if (typeof cacheRetrievalObj !== "undefined") {
					ValotaEngine.ChromeOS.cacheFile(cacheRetrievalObj.name, cacheRetrievalObj.url, cacheRetrievalObj.callback);
				}
				break;
			case "logs":
				// TODO log the origin to change the origin check
				console.log("[Engine ChromeOS] Log from the ChromeOS app:", e.data);
				if (typeof _VALOTA_LOG_CHROME_OS !== 'undefined' && _VALOTA_LOG_CHROME_OS === true) {
					if (e.data.data.length > 0) {
						var request = {action: "valota_api/log/chrome_os_log", appUUID: "ValotaApp", displayUUID: _displayID, message: JSON.stringify(e.data.data)};
						$.ajax({
							type: "POST",
							url: _VALOTA_REST,
							data: request,
							dataType: "json"
						}).done(function () {})
							.fail(function (xhr, st, err) {
								var mes = 'Failed to send log: ' + err + ' (' + st + ')';
								console.error("[Engine ChromeOS] " + mes);
							});
					}
				}
				break;
			case "busy":
				console.error("[Engine ChromeOS] bad coding, busy"); // shouldn't bother when something is going on
				if (ValotaEngine.ChromeOS.callback !== null) {
					ValotaEngine.ChromeOS.callback(e.data.name, e.data.url, '', {status: "error", message: _('Bad coding')});
				}
				ValotaEngine.ChromeOS.callback = null;
				ValotaEngine.ChromeOS.name = null;
				ValotaEngine.ChromeOS.url = null;
				break;
			case "videoStarted":
				if (e.data.url !== ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].url) {
					console.error("[Engine ChromeOS] wrong video playing?", e.data, ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].url);
				} else {
					if (typeof ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].videoStartedPlayingCB === 'function') {
						ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].videoStartedPlayingCB(e.data.duration);
					} else {
						console.error("[Engine ChromeOS] video playing, no callback");
					}
				}
				break;
			case "videoEnded":
				if (e.data.url !== ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].url) {
					console.error("[Engine ChromeOS] wrong video ended?", e.data.url, ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].url);
				} else {
					if (typeof ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].videoEndedCB === 'function') {
						ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].videoEndedCB();
					} else {
						console.error("[Engine ChromeOS] video ended, no callback");
					}
				}
				break;
			case "videoErrored":
				if (e.data.url !== ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].url) {
					console.error("[Engine ChromeOS] wrong video errored?");
				} else {
					if (typeof ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].videoErrorCB === 'function') {
						console.error("[Engine ChromeOS] video errored", e.data);
						ValotaEngine.ChromeOS.videoOwners[e.data.ownerUuid].videoErrorCB(e.data);
					} else {
						console.error("[Engine ChromeOS] video errored, no callback", e.data);
					}
				}
				break;

			case "screenshot":

				if (typeof ValotaEngine.ChromeOS.screenshotCallback === 'function') {
					ValotaEngine.ChromeOS.screenshotCallback(e.data.url);
				}
				break;
			case "overlay_crash":
				logError(e.data.message, e.data.uuid, e.data.severity);
				break;
			default:
				console.warn("[Engine ChromeOS] unknown command " + e.data.cmd);
		}
	},
	fileCachedCallback: function (name, url, unused, status) {
		console.log("[Engine ChromeOS] file cached", url);
		for (var i = 0; i < ValotaEngine.Video.cacheList[name].cb.length; i++) {
			ValotaEngine.Video.cacheList[name].cb[i](url, status);
		}
		delete ValotaEngine.Video.cacheList[name];
	},
	cacheFile: function (name, url, callback) {
		console.log("[Engine ChromeOS] loading", url);
		if (typeof ValotaEngine.ChromeOS.appWindow === "undefined") {
			var tempObj = {};
			tempObj.name = name;
			tempObj.url = url;
			tempObj.callback = callback;
			ValotaEngine.ChromeOS.cacheQueue.push(tempObj);
			return;
		}
		if (typeof ValotaEngine.ChromeOS.files[name] !== 'undefined') {
			if (ValotaEngine.ChromeOS.files[name].loading === true) {
				throw "bad coding, already loading shouldn't have come this far";
			} else {
				window.setTimeout(function () {
					callback(name, url, '', {status: 'ok'});
				}, 10);
				return;
			}
		}
		if (ValotaEngine.ChromeOS.callback !== null) {
			// cacheing something already, put this to queue
			var tempObj = {};
			tempObj.name = name;
			tempObj.url = url;
			tempObj.callback = callback;
			ValotaEngine.ChromeOS.cacheQueue.push(tempObj);
			return;
		}
		ValotaEngine.ChromeOS.callback = callback;
		ValotaEngine.ChromeOS.name = name;
		ValotaEngine.ChromeOS.url = url;
		ValotaEngine.ChromeOS.files[name] = {};
		ValotaEngine.ChromeOS.files[name].loading = true;
		ValotaEngine.ChromeOS.files[name].url = null;
		ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "load", filename: name, url: url}, ValotaEngine.ChromeOS.appOrigin);
	},
	playVideo: function (uuid, url, elem, playStartedCB, endedCB, errorCB, iframeRect) {
		var name = "v_" + JSON.stringify(url).replace(/[^0-9a-z]/gi, '');
		if ((!(name in ValotaEngine.ChromeOS.files)) || ValotaEngine.ChromeOS.files[name].loading) {
			throw "trying to play an uncached video";
		}
		if (!(uuid in ValotaEngine.ChromeOS.videoOwners)) {
			ValotaEngine.ChromeOS.videoOwners[uuid] = {elem: null, videoStartedPlayingCB: null, videoEndedCB: null, videoErrorCB: null};
		}
		if (ValotaEngine.ChromeOS.videoOwners[uuid].elem !== null) {
			if (ValotaEngine.ChromeOS.videoOwners[uuid].elem === elem) {
				throw "bad coding, use replayVideo to replay a video :O";
			}
			throw "bad coding, a video set when trying to play video. stop first";
		}

//		console.log(elem.getBoundingClientRect());
		var rect = elem.getBoundingClientRect();
		console.log(rect);

		var coordObj = {width: rect.width, height: rect.height, left: (rect.left + iframeRect.left), top: (rect.top + iframeRect.top)};
		ValotaEngine.ChromeOS.videoOwners[uuid].elem = elem;
		ValotaEngine.ChromeOS.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.ChromeOS.videoOwners[uuid].videoEndedCB = endedCB;
		ValotaEngine.ChromeOS.videoOwners[uuid].videoErrorCB = errorCB;
		ValotaEngine.ChromeOS.videoOwners[uuid].url = url;
		ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "play", filename: name, ownerUuid: uuid, url: url, rect: coordObj, mute: ValotaEngine.Video.getMuteStatus(uuid)}, ValotaEngine.ChromeOS.appOrigin);
	},
	resizeVideo: function (uuid, elem, iframeRect) {
		if (ValotaEngine.ChromeOS.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to resize video when something else is playing";
		}
		var rect = elem.getBoundingClientRect();
		var coordObj = {width: rect.width, height: rect.height, left: (rect.left + iframeRect.left), top: (rect.top + iframeRect.top)};
		ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "resize", ownerUuid: uuid, url: ValotaEngine.ChromeOS.videoOwners[uuid].url, rect: coordObj}, ValotaEngine.ChromeOS.appOrigin);
	},
	stopVideo: function (uuid, elem) {
		if (typeof ValotaEngine.ChromeOS.videoOwners[uuid] === 'undefined') {
			throw "unknown uuid"; // never played anything
		}
		if (ValotaEngine.ChromeOS.videoOwners[uuid].elem === null) {
			throw "not running"; // played but not running
		}
		if (ValotaEngine.ChromeOS.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to stop video when something else is playing";
		}
		ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "stop", ownerUuid: uuid}, ValotaEngine.ChromeOS.appOrigin);
		ValotaEngine.ChromeOS.videoOwners[uuid].videoEndedCB = null;
		ValotaEngine.ChromeOS.videoOwners[uuid].videoErrorCB = null;
		ValotaEngine.ChromeOS.videoOwners[uuid].videoStartedPlayingCB = null;
		ValotaEngine.ChromeOS.videoOwners[uuid].elem = null;
		ValotaEngine.ChromeOS.videoOwners[uuid].url = null;
	},
	replayVideo: function (uuid, elem, playStartedCB, errorCB) {
		if (typeof ValotaEngine.ChromeOS.videoOwners[uuid] === 'undefined') {
			throw "unknown uuid"; // never played anything
		}
		if (ValotaEngine.ChromeOS.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to replay video that is not playing";
		}
		ValotaEngine.ChromeOS.appWindow.postMessage({cmd: "replay", ownerUuid: uuid, url: ValotaEngine.ChromeOS.videoOwners[uuid].url}, ValotaEngine.ChromeOS.appOrigin);
		ValotaEngine.ChromeOS.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.ChromeOS.videoOwners[uuid].videoErrorCB = errorCB;
	},
	stopVideos: function () {
		for (var uuid in ValotaEngine.ChromeOS.videoOwners) {
			if (ValotaEngine.ChromeOS.videoOwners[uuid].elem !== null) {
				try {
					ValotaEngine.ChromeOS.videoOwners[uuid].videoEndedCB();
					ValotaEngine.ChromeOS.stopVideo(uuid, ValotaEngine.ChromeOS.videoOwners[uuid].elem);
				} catch (e) {
					// do nothing now
				}
			}
		}
	}
};