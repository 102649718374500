/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

/* global _applicationData */

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}

ValotaEngine.Palette = {

	get: function (uuid, type, num) {
		if (typeof (num) === "undefined") {
			num = 0;
		}
		var loc = getStoryLoc(uuid);

		if (typeof _applicationData[loc].palette === 'undefined' || !_applicationData[loc].palette.palette) {
			return false;
		}

		var targ = null;

		switch (type) {
			case "color":
				targ = _applicationData[loc].palette.palette.colors[num % _applicationData[loc].palette.palette.colors.length].color;
				break;

			case "support":
				targ = _applicationData[loc].palette.palette.supports[num % _applicationData[loc].palette.palette.supports.length].color;
				break;

			case "font":
				targ = _applicationData[loc].palette.palette.bodyFont;
				break;
			case "titlefont":
				targ = _applicationData[loc].palette.palette.titleFont;
				break;
			case "titleFontColor":
				targ = _applicationData[loc].palette.palette.titleFontColor;
				break;
			case "bodyFontColor":
				targ = _applicationData[loc].palette.palette.bodyFontColor;
				break;
			case "appbgcolor":
				targ = _applicationData[loc].palette.palette.backgrounds[num % _applicationData[loc].palette.palette.backgrounds.length];
				targ = tinycolor(targ.appBgColor).setAlpha(targ.appBgOpacity).toString();
				break;
			case "unobtrusive":
			case "unobtrusivebg": // fall through
				var color = tinycolor(_applicationData[loc].palette.palette.colors[0].color);
				var colorLuminance = color.getLuminance();
//				var appBgColor = tinycolor(_applicationData[loc].palette.palette.backgrounds[num % _applicationData[loc].palette.palette.backgrounds.length].appBgColor).setAlpha(_applicationData[loc].palette.palette.backgrounds[num % _applicationData[loc].palette.palette.backgrounds.length].appBgOpacity);
				var bg = tinycolor(_applicationData[loc].palette.palette.backgrounds[num % _applicationData[loc].palette.palette.backgrounds.length].appBgColor);

				var bgAlpha = _applicationData[loc].palette.palette.backgrounds[num % _applicationData[loc].palette.palette.backgrounds.length].appBgOpacity;
				if (bgAlpha < 1) {
					bgAlpha *= 0.7;
				}
				var bgLuminance = tinycolor(bg).getLuminance();

				var factor = 1.0;

				if (type === "unobtrusive") {
					if (colorLuminance >= bgLuminance) {
						factor = 0.7;
					} else {
						factor = 1.35;
					}
					targ = "rgb(" + Math.floor(color._r * factor) + "," + Math.floor(color._g * factor) + "," + Math.floor(color._b * factor) + ")";
				} else { // unobtrusive background
					if (colorLuminance <= bgLuminance) {
						factor = 0.9;
					} else {
						factor = 1.2;
					}
					targ = "rgba(" + Math.floor(bg._r * factor) + "," + Math.floor(bg._g * factor) + "," + Math.floor(bg._b * factor) + "," + bgAlpha + ")";
				}
				break;
			case "transitionTime":
				targ = 500;
				break;
			default:
				return false;
		}
		if (targ === null) {
			return null;
		}

		return JSON.parse(JSON.stringify(targ));
	}
};