/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

/* global URL, _VALOTA_LOG_CHROME_OS, _displayID, _VALOTA_REST, _applicationData */


if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}

ValotaEngine.ElectronApp = {
	inited: false,
	PIN: null,
	callback: null,
	cacheQueue: [],
	files: {},
	videoOwners: [],
	screenshotCallback: false,
	customProtocol:'valota-cache-protocol',
	appOrigin: null,
	overlayPlaying: false,
	emergencyActive: false,
	overlayData: null,
	start: function () {
		if (!ValotaEngine.ElectronApp.inited) {
			window.addEventListener('message', ValotaEngine.ElectronApp.onMessage);
			ValotaEngine.ElectronApp.inited = true;
		}
		const claimPin = document.getElementById('claim_pin');
		if(claimPin) {
			claimPin.classList.add('electron');
		}
		ValotaEngine.ElectronApp.sendToApp({cmd:"started"});
	},
	sendToApp: function(obj) {
		obj.channel = 'valota-electron-app';
		window.postMessage(obj, ValotaEngine.ElectronApp.appOrigin);
	},
	clearUUID: function () {
		if (!ValotaEngine.ElectronApp.appOrigin) {
			console.error("[Engine ElectronApp] clear UUID but no contact to Electron App");
		}
		ValotaEngine.ElectronApp.sendToApp({cmd: "clear_uuid"});
	},
	saveUUID: function (uuid) {
		console.warn("[Engine ElectronApp] save uuid", uuid);
		if (!ValotaEngine.ElectronApp.appOrigin) {
			console.error("[Engine ElectronApp] save UUID but no contact to Electron App");
		}
		ValotaEngine.ElectronApp.sendToApp({cmd: "save_uuid", uuid: uuid});
	},
	getScreenshot: function (callback) {
		ValotaEngine.ElectronApp.screenshotCallback = callback;
		ValotaEngine.ElectronApp.sendToApp({cmd: "get_screenshot"});

	},
	reloadApp: function () {
		ValotaEngine.ElectronApp.sendToApp({cmd: "reload"});
	},
	cacheNext:function () {
		let cacheRetrievalObj = ValotaEngine.ElectronApp.cacheQueue.shift();
		if (typeof cacheRetrievalObj !== "undefined") {
			ValotaEngine.ElectronApp.cacheFile(cacheRetrievalObj.name, cacheRetrievalObj.url, cacheRetrievalObj.callback);
		}
	},
	onMessage: function (e) {

		if (typeof e.data !== "object" || typeof e.data.channel === 'undefined' || e.data.channel !== 'valota-electron-engine') {
			return;
		}

		if(!e.data.hasOwnProperty("cmd")) {
			console.warn("[Engine ElectronApp] Message syntax error");
		}

		switch (e.data.cmd) {
			case "initial":
				if (e.data.displayUUID && _displayID !== e.data.displayUUID) {
					// got a different displayUUID from the app than what we are using, switching
					logError("switching displayUUID like ElectronApp dictates, new [" + e.data.displayUUID + "]", ValotaEngine.ElectronApp.not_defined, "warning");

					_displayID = e.data.displayUUID;
					setLocal('display', _displayID);
					window.setTimeout(function () {
						console.log("reloading");
						reloadDisplay(false);
					}, 10000);
				}
				console.log("[Engine ElectronApp] initial uuid", e.data.displayUUID);
				ValotaEngine.ElectronApp.appOrigin = e.origin;
				if (e.data.displayUUID === null) {
					ValotaEngine.ElectronApp.saveUUID(_displayID);
				}

				ValotaEngine.ElectronApp.cacheNext();
				break;
			case "device_id":
				console.log("[Engine ElectronApp] device id", e.data.deviceID);
				sendMyDeviceID(e.data.deviceID);
				break;
			case "bt_address":
				console.log("[Engine ElectronApp] BT address", e.data.btAddress);
				sendMyBTAddress(e.data.btAddress);
				break;
			case "loaded":
				if (e.data.filename !== ValotaEngine.ElectronApp.name ||
					e.data.url !== ValotaEngine.ElectronApp.url) {
					console.error("[Engine ElectronApp] bad coding", e.data);
					if (ValotaEngine.ElectronApp.callback !== null) {
						ValotaEngine.ElectronApp.callback(e.data.filename, e.data.url, '', {status: "error", message: _('Bad coding')});
					}
					ValotaEngine.ElectronApp.callback = null;
					ValotaEngine.ElectronApp.name = null;
					ValotaEngine.ElectronApp.url = null;
					return;
				}
				if (ValotaEngine.ElectronApp.callback !== null) {
					ValotaEngine.ElectronApp.callback(e.data.filename, e.data.url, '', {status: 'ok'});
				}
				ValotaEngine.ElectronApp.callback = null;
				ValotaEngine.ElectronApp.name = null;
				ValotaEngine.ElectronApp.url = null;
				ValotaEngine.ElectronApp.files[e.data.filename].loading = false;
				ValotaEngine.ElectronApp.cacheNext();
				break;
			case "error":
				console.log("[Engine ElectronApp] error message received", e.data);
				if (ValotaEngine.ElectronApp.callback !== null) {
					ValotaEngine.ElectronApp.callback(e.data.filename, e.data.url, '', {status: "error", message: _("filesystem error: " + e.data.message)});
					console.error("[Engine ElectronApp] file load error", e.data.message, e.data.reason);
				}
				ValotaEngine.ElectronApp.callback = null;
				ValotaEngine.ElectronApp.name = null;
				ValotaEngine.ElectronApp.url = null;
				ValotaEngine.ElectronApp.files[e.data.filename].loading = false;
				ValotaEngine.ElectronApp.cacheNext();
				break;
			case "busy":
				console.error("[Engine ElectronApp] bad coding, busy"); // shouldn't bother when something is going on
				if (ValotaEngine.ElectronApp.callback !== null) {
					ValotaEngine.ElectronApp.callback(e.data.name, e.data.url, '', {status: "error", message: _('Bad coding')});
				}
				ValotaEngine.ElectronApp.callback = null;
				ValotaEngine.ElectronApp.name = null;
				ValotaEngine.ElectronApp.url = null;
				break;
			case "videoStarted":
				if (e.data.url !== ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].url) {
					console.error("[Engine ElectronApp] wrong video playing?", e.data, ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].url);
				} else {
					if (typeof ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].videoStartedPlayingCB === 'function') {
						ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].videoStartedPlayingCB(e.data.duration);
					} else {
						console.error("[Engine ElectronApp] video playing, no callback");
					}
				}
				break;
			case "videoEnded":
				if (e.data.url !== ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].url) {
					console.error("[Engine ElectronApp] wrong video ended?", e.data.url, ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].url);
				} else {
					if (typeof ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].videoEndedCB === 'function') {
						ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].videoEndedCB();
					} else {
						console.error("[Engine ElectronApp] video ended, no callback");
					}
				}
				break;
			case "videoErrored":
				if (e.data.url !== ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].url) {
					console.error("[Engine ElectronApp] wrong video errored?");
				} else {
					if (typeof ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].videoErrorCB === 'function') {
						console.error("[Engine ElectronApp] video errored", e.data);
						ValotaEngine.ElectronApp.videoOwners[e.data.ownerUuid].videoErrorCB(e.data);
					} else {
						console.error("[Engine ElectronApp] video errored, no callback", e.data);
					}
				}
				break;

			case "screenshot":

				if (typeof ValotaEngine.ElectronApp.screenshotCallback === 'function') {
					ValotaEngine.ElectronApp.screenshotCallback(e.data.url);
				}
				break;
			default:
				console.warn("[Engine ElectronApp] unknown command " + e.data.cmd);
		}
	},
	fileCachedCallback: function (name, url, unused, status) {
		console.log("[Engine ElectronApp] file cached", url);
		for (var i = 0; i < ValotaEngine.Video.cacheList[name].cb.length; i++) {
			ValotaEngine.Video.cacheList[name].cb[i](url, status);
		}
		delete ValotaEngine.Video.cacheList[name];
	},
	cacheFile: function (name, url, callback) {
		console.log("[Engine ElectronApp] loading", url);
		if (!ValotaEngine.ElectronApp.inited) {
			let tempObj = {};
			tempObj.name = name;
			tempObj.url = url;
			tempObj.callback = callback;
			ValotaEngine.ElectronApp.cacheQueue.push(tempObj);
			return;
		}
		if (typeof ValotaEngine.ElectronApp.files[name] !== 'undefined') {
			if (ValotaEngine.ElectronApp.files[name].loading === true) {
				throw "bad coding, already loading shouldn't have come this far";
			} else {
				window.setTimeout(function () {
					callback(name, url, '', {status: 'ok'});
				}, 10);
				return;
			}
		}
		if (ValotaEngine.ElectronApp.callback !== null) {
			// cacheing something already, put this to queue
			var tempObj = {};
			tempObj.name = name;
			tempObj.url = url;
			tempObj.callback = callback;
			ValotaEngine.ElectronApp.cacheQueue.push(tempObj);
			return;
		}
		ValotaEngine.ElectronApp.callback = callback;
		ValotaEngine.ElectronApp.name = name;
		ValotaEngine.ElectronApp.url = url;
		ValotaEngine.ElectronApp.files[name] = {};
		ValotaEngine.ElectronApp.files[name].loading = true;
		ValotaEngine.ElectronApp.files[name].url = null;
		ValotaEngine.ElectronApp.sendToApp({cmd: "load", filename: name, url: url});
	},
	playVideo: function (uuid, url, elem, playStartedCB, endedCB, errorCB, iframeRect) {
		let name = "v_" + JSON.stringify(url).replace(/[^0-9a-z]/gi, '');
		if ((!(name in ValotaEngine.ElectronApp.files)) || ValotaEngine.ElectronApp.files[name].loading) {
			throw "trying to play an uncached video";
		}
		if (!(uuid in ValotaEngine.ElectronApp.videoOwners)) {
			ValotaEngine.ElectronApp.videoOwners[uuid] = {elem: null, videoStartedPlayingCB: null, videoEndedCB: null, videoErrorCB: null};
		}
		if (ValotaEngine.ElectronApp.videoOwners[uuid].elem !== null) {
			if (ValotaEngine.ElectronApp.videoOwners[uuid].elem === elem) {
				throw "bad coding, use replayVideo to replay a video :O";
			}
			throw "bad coding, a video set when trying to play video. stop first";
		}

//		console.log(elem.getBoundingClientRect());
//		var rect = elem.getBoundingClientRect();

//		var coordObj = {width: rect.width, height: rect.height, left: (rect.left + iframeRect.left), top: (rect.top + iframeRect.top)};
		ValotaEngine.ElectronApp.videoOwners[uuid].elem = elem;
		elem.valotaPlaybackStartedCB = playStartedCB;
		elem.valotaVideoErrorCB = errorCB;
		elem.valotaVideoEndedCB = endedCB;
		elem.valotaUuid = uuid;
		ValotaEngine.ElectronApp.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.ElectronApp.videoOwners[uuid].videoEndedCB = endedCB;
		ValotaEngine.ElectronApp.videoOwners[uuid].videoErrorCB = errorCB;

		ValotaEngine.ElectronApp.videoOwners[uuid].url = url;
		ValotaEngine.ElectronApp.videoOwners[uuid].name = name;
		elem.src = ValotaEngine.ElectronApp.customProtocol + '://' + name;
		elem.addEventListener('loadedmetadata', (ev) => {
			ev.target.valotaPlaybackStartedCB(ev.target.duration, ev.target.valotaUuid);
			ev.target.play();
		});
		elem.addEventListener('ended', (ev) => {
			ev.target.valotaVideoEndedCB();
		});
		elem.addEventListener('error', (ev) => {
			if(!ev.target.error || !ev.target.error.message || ev.target.error.message !== 'MEDIA_ELEMENT_ERROR: Empty src attribute') {
				ev.target.valotaVideoErrorCB(ev, uuid);
			}

		});
		elem.muted = ValotaEngine.Video.getMuteStatus(uuid);
		elem.load();
		ValotaEngine.ElectronApp.sendToApp({cmd: "play", filename: name});

//		ValotaEngine.ElectronApp.sendToApp({cmd: "play", filename: name, ownerUuid: uuid, url: url, rect: coordObj, mute: ValotaEngine.Video.getMuteStatus(uuid)});
	},
	resizeVideo: function (uuid, elem, iframeRect) {
		if (ValotaEngine.ElectronApp.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to resize video when something else is playing";
		}
		//var rect = elem.getBoundingClientRect();
		//var coordObj = {width: rect.width, height: rect.height, left: (rect.left + iframeRect.left), top: (rect.top + iframeRect.top)};
		//ValotaEngine.ElectronApp.sendToApp({cmd: "resize", ownerUuid: uuid, url: ValotaEngine.ElectronApp.videoOwners[uuid].url, rect: coordObj});
	},
	stopVideo: function (uuid, elem) {
		if (typeof ValotaEngine.ElectronApp.videoOwners[uuid] === 'undefined') {
			throw "unknown uuid"; // never played anything
		}
		if (ValotaEngine.ElectronApp.videoOwners[uuid].elem === null) {
			throw "not running"; // played but not running
		}
		if (ValotaEngine.ElectronApp.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to stop video when something else is playing";
		}
		//ValotaEngine.ElectronApp.sendToApp({cmd: "stop", ownerUuid: uuid});
		elem.pause();
		ValotaEngine.ElectronApp.videoOwners[uuid].videoEndedCB = null;
		ValotaEngine.ElectronApp.videoOwners[uuid].videoErrorCB = null;
		ValotaEngine.ElectronApp.videoOwners[uuid].videoStartedPlayingCB = null;
		ValotaEngine.ElectronApp.videoOwners[uuid].elem = null;
		ValotaEngine.ElectronApp.videoOwners[uuid].url = null;
	},
	replayVideo: function (uuid, elem, playStartedCB, errorCB) {
		if (ValotaEngine.ElectronApp.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to replay video that is not playing";
		}
		elem.valotaPlaybackStartedCB = playStartedCB;
		elem.valotaVideoErrorCB = errorCB;
		elem.currentTime = 0;

		elem.load();
		//ValotaEngine.ElectronApp.sendToApp({cmd: "replay", ownerUuid: uuid, url: ValotaEngine.ElectronApp.videoOwners[uuid].url});
		ValotaEngine.ElectronApp.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.ElectronApp.videoOwners[uuid].videoErrorCB = errorCB;
		ValotaEngine.ElectronApp.sendToApp({cmd: "replay", filename: ValotaEngine.ElectronApp.videoOwners[uuid].name});
	},
	stopVideos: function () {
		for (var uuid in ValotaEngine.ElectronApp.videoOwners) {
			if (ValotaEngine.ElectronApp.videoOwners[uuid].elem !== null) {
				try {
					ValotaEngine.ElectronApp.videoOwners[uuid].videoEndedCB();
					ValotaEngine.ElectronApp.stopVideo(uuid, ValotaEngine.ElectronApp.videoOwners[uuid].elem);
				} catch (e) {
					// do nothing now
				}
			}
		}
	}
};