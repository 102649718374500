/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

/* global _applicationData */

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}

ValotaEngine.Sources = {

	get: function (uuid) {

		var source = _applicationData[getStoryLoc(uuid)].source;

		if (source && typeof source.data !== "undefined") {
			return JSON.parse(JSON.stringify(source.data));
		}

		return null;

	},
	getLatestContent: function (uuid) {
		var source = _applicationData[getStoryLoc(uuid)].source;

		if (source && typeof source.latestContent !== "undefined") {
			return parseInt(source.latestContent);
		}

		return null;
	}

};

