/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

/* global _VALOTA_DEVICE, _CURRENT_WINDOW, _VALOTA_REST, _LATITUDE, _LONGITUDE, _VALOTA_TIZEN, _VALOTA_HOME */

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}

ValotaEngine.ClaimDisplay = {

	identifier: null,
	curStep: 1,
	inited: false,
	device: false,
	show: function () {
		if (typeof _VALOTA_DEVICE !== 'undefined' || typeof _VALOTA_TIZEN !== 'undefined' || typeof _VALOTA_ELECTRON !== 'undefined' || (typeof _VALOTA_EXTENSION !== 'undefined' && ValotaEngine.ChromeExtension.role === 'player')) {
			ValotaEngine.ClaimDisplay.device = true;
		}
		if (ValotaEngine.ClaimDisplay.device === true) {
			$('#recovery_id').html(ValotaEngine.ClaimDisplay.identifier);
			if (!ValotaEngine.ClaimDisplay.inited) {
				ValotaEngine.ClaimDisplay.inited = true;
				$('#input_pin').on('input', ValotaEngine.ClaimDisplay.checkPIN);
				$('#pin_btn').on('click', ValotaEngine.ClaimDisplay.sendPIN);
			}
			if (_CURRENT_WINDOW !== 'enter_pin') {
				showWindow('enter_pin');
			}
			document.getElementById('qrcode').innerHTML = "";
			var qrcode = new QRCode(document.getElementById('qrcode'), {
				text: _VALOTA_HOME + "#claim/" + ValotaEngine.ClaimDisplay.identifier,
				width: 128,
				height: 128,
				colorDark: 'black',
				colorLight: 'white'
			});
			document.getElementById('claim_pin').innerHTML = ValotaEngine.ClaimDisplay.identifier;
		} else {
			$('#claim_id').html(ValotaEngine.ClaimDisplay.identifier);
			if (!ValotaEngine.ClaimDisplay.inited) {
				ValotaEngine.ClaimDisplay.inited = true;
				$('#input_display_name').on('input', ValotaEngine.ClaimDisplay.checkName);

				$('#claim_link').keyup(ValotaEngine.ClaimDisplay.checkLink);
				$('#claim_email').keyup(ValotaEngine.ClaimDisplay.checkLogin);
				$('#claim_password').keyup(ValotaEngine.ClaimDisplay.checkLogin);

				$('#claim_login_btn').on('click', ValotaEngine.ClaimDisplay.sendLogin);

			}
			if (_CURRENT_WINDOW !== 'claim_display') {
				showWindow('claim_display');
			}
		}

	},
	disableAll: function (disable) {
		if (typeof (disable) === 'undefined') {
			disable = true;
		}
		document.getElementById('claim_login_btn').disabled = disable;
		document.getElementById('claim_link_btn').disabled = disable;
		document.getElementById('claim_link').disabled = disable;
		document.getElementById('claim_email').disabled = disable;
		document.getElementById('claim_password').disabled = disable;
		document.getElementById('input_display_name').disabled = disable;

		if (disable === false) {
			ValotaEngine.ClaimDisplay.checkLink();
			ValotaEngine.ClaimDisplay.checkLogin();
		}
	},
	sendPIN: function () {
		var el = document.getElementById('pin_working');
		el.innerHTML = '';
		el.appendChild(getLoadingDiv('small'));
		el.appendChild(document.createTextNode(' ' + _('Checking PIN.') + ''));
		$.ajax({
			type: "POST",
			url: _VALOTA_REST,
			data: {
				action: 'valota_api/displays/enter_pin',
				displayUUID: _displayID,
				pin: document.getElementById('input_pin').value
			},
			success: function (data) {
				console.log("[Engine ClaimDisplay]", data);
				if (data.status === 'ok') {
					if (data.response.display_uuid.length === 32) {
						setDisplayName(data.response.display_name);
						setClaimDate(data.response.claim_date);
						setOwnerLogo(data.response.owner_logo);
						setLocal('display', data.response.display_uuid);
						_displayID = data.response.display_uuid;
						waitForContent();
						if (_RESIZE_TIMEOUT) {
							clearTimeout(_RESIZE_TIMEOUT);
							_RESIZE_TIMEOUT = null;
						}
						_RESIZE_TIMEOUT = setTimeout(sendDimensions, 300000); // 5 minutes
					} else {
						alert("display identifier is not valid");
					}
					document.getElementById('pin_working').innerHTML = '';
				} else {
					var add = [];
					for (var i in  data.status_messages) {
						if (data.status_messages[i].console) {
							console.warn("[Engine ClaimDisplay] " + data.status_messages[i].message);
						} else {
							add.push(data.status_messages[i].message);
						}
					}
					document.getElementById('pin_working').innerHTML = add.join("<br>");
				}
				ValotaEngine.ClaimDisplay.disableAll(false);
			},
			error: function () {
				ValotaEngine.ClaimDisplay.disableAll(false);
				document.getElementById('pin_working').innerHTML = '';
				alert('Unable to contact server.');
			},
			dataType: "json"
		});
	},
	sendLogin: function () {

		var el = document.getElementById('login_working');
		el.innerHTML = '';
		el.appendChild(getLoadingDiv('small'));
		el.appendChild(document.createTextNode(' ' + _('Checking login') + ''));
		ValotaEngine.ClaimDisplay.disableAll(true);
		//resize
		ValotaEngine.ClaimDisplay.step2();


		$.ajax({
			type: "POST",
			url: _VALOTA_REST,
			data: {
				action: 'valota_api/displays/claim_login',
				displayUUID: _displayID,
				claim_email: document.getElementById('claim_email').value,
				claim_password: document.getElementById('claim_password').value,
				latitude: _LATITUDE,
				longitude: _LONGITUDE,
				display_name: document.getElementById('input_display_name').value

			},
			success: function (data) {
				console.log("[Engine ClaimDisplay]", data);
				if (data.status === 'ok') {
					if (data.response.display_uuid.length === 32) {
						setDisplayName(data.response.display_name);
						setClaimDate(data.response.claim_date);
						setOwnerLogo(data.response.owner_logo);
						setLocal('display', data.response.display_uuid);

						_displayID = data.response.display_uuid;
						if (_RESIZE_TIMEOUT) {
							clearTimeout(_RESIZE_TIMEOUT);
							_RESIZE_TIMEOUT = null;
						}
						_RESIZE_TIMEOUT = setTimeout(sendDimensions, 300000); // 5 minutes
						waitForContent();
					} else {
						alert("display identifier is not valid");
					}

					document.getElementById('login_working').innerHTML = '';
				} else {
					var add = [];
					for (var i in  data.status_messages) {
						if (data.status_messages[i].console) {
							console.warn("[Engine ClaimDisplay] " + data.status_messages[i].message);
						} else {
							add.push(data.status_messages[i].message);
						}

					}
					document.getElementById('login_working').innerHTML = add.join("<br>");
				}
				ValotaEngine.ClaimDisplay.step2();

				ValotaEngine.ClaimDisplay.disableAll(false);
			},
			error: function () {
				ValotaEngine.ClaimDisplay.disableAll(false);
				document.getElementById('login_working').innerHTML = '';
				alert('Server responded with an error code. Please try again');
			},
			dataType: "json"
		});

	},
	checkLogin: function (e) {

		var email = document.getElementById('claim_email');
		var password = document.getElementById('claim_password');

		var disabled = true;
		var btn = document.getElementById('claim_login_btn');
		if (typeof email.checkValidity !== 'function') {
			if (email.value.length && password.value.length) {
				disabled = false;
			}
		} else {
			if (email.checkValidity() && password.checkValidity()) {
				disabled = false;
			}
		}
		btn.disabled = disabled;

		if (typeof e !== 'undefined' && e.keyCode === 13) {
			btn.click();
		}
	},
	checkLink: function (e) {
		var link = document.getElementById('claim_link');
		var disabled = true;
		var btn = document.getElementById('claim_link_btn');
		if (typeof link.checkValidity !== 'function') {
			if (link.value.length) {
				disabled = false;
			}
		} else {
			if (link.checkValidity()) {
				disabled = false;
			}
		}
		btn.disabled = disabled;
		if (typeof e !== 'undefined' && e.keyCode === 13) {
			btn.click();
		}
	},
	checkPIN: function () {
		var name = $('#input_pin');
		if (name.val().length === 8) {
			$('#pin_btn').prop('disabled', false);
		} else {
			$('#pin_btn').prop('disabled', true);
		}
	},
	checkName: function () {
		var name = $('#input_display_name');

		if (name.val().length) {
			if (ValotaEngine.ClaimDisplay.curStep === 1) {
				ValotaEngine.ClaimDisplay.step2();
			}
		} else {
			ValotaEngine.ClaimDisplay.step1();
		}
	},
	step1: function () {
		ValotaEngine.ClaimDisplay.curStep = 1;
		$('#claim_valota_logo').css('height', "50vmin");
		$('#claim_step_2').css('height', 0);
	},
	step2: function () {
		ValotaEngine.ClaimDisplay.curStep = 2;
		var el = document.getElementById('claim_step_2');
		$('#claim_valota_logo').css('height', "0");
		el.style.height = el.scrollHeight + 'px';
	}
};


