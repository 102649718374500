/*
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2016] Valota Limited
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */


/* global continueRunning */

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}


/**
 * Tries to maintain connection with the primary node server.
 * 
 * If connected to other node, will close that connection in two hours.
 * 
 */
ValotaEngine.WebSocket = {
	socket: null,
	socketStatus: "not_connected",
	url: null,
	opened: null,
	lastConnected_ms: null,
	disconnectedConnected_ms: null,
	reconnectTimeout: null,
	tryPrimaryNodeTimeout: null,
	tryPrimaryNode_ms: 2 * 60 * 60 * 1000, // 2 hours
//	tryPrimaryNode_ms: 2 * 60 * 1000, // 2 minutes
	closedConnections: [],
	nodes: [],
	nextConnectTime: null,
	currentNode: -1,
	timesyncSent: 0,
	timesyncInterval: null,
	supported: function () {
		return window.WebSocket ? true : false;
	},
	connected: function () {
		return  ValotaEngine.WebSocket.socket !== null && ValotaEngine.WebSocket.socket.readyState !== 3 && ValotaEngine.WebSocket.socket.readyState !== 2;

	},
	connect: function () {
		if(!continueRunning){
			// we should not run
			return;
		}
		if (ValotaEngine.WebSocket.reconnectTimeout) {
			console.log("[Engine WebSocket] Cleared reconnect timeout");
			window.clearTimeout(ValotaEngine.WebSocket.reconnectTimeout);
			ValotaEngine.WebSocket.reconnectTimeout = null;
		}
		if (ValotaEngine.WebSocket.nextConnectTime !== null && ValotaEngine.WebSocket.nextConnectTime > Date.now()) {
			ValotaEngine.WebSocket.currentNode = -1;
			ValotaEngine.WebSocket.reconnectTimeout = window.setTimeout(ValotaEngine.WebSocket.reconnect, ValotaEngine.WebSocket.nextConnectTime - Date.now() + 1000);
			return;
		}
		if (ValotaEngine.WebSocket.nodes.length === 0) {
			// no nodes
			logError("[Engine WebSocket] no nodes " + ValotaEngine.WebSocket.currentNode);
			ValotaEngine.WebSocket.closedConnections.push({
				opened: null,
				url: ValotaEngine.WebSocket.url,
				from: "no_nodes",
				closed: Date.now()
			});
			if (_REQUEST_TIMER === null) {
				fetchData();
			}
			return;
		}
		/*
		 if (typeof ValotaEngine.WebSocket.nodes[ValotaEngine.WebSocket.currentNode] !== 'undefined') {
		 ValotaEngine.WebSocket.url = ValotaEngine.WebSocket.nodes[ValotaEngine.WebSocket.currentNode];
		 setLocal('node_server', ValotaEngine.WebSocket.url);
		 }
		 */
		if (ValotaEngine.WebSocket.currentNode < 0) {
			ValotaEngine.WebSocket.currentNode = 0;
		}
		if (typeof ValotaEngine.WebSocket.nodes[ValotaEngine.WebSocket.currentNode] !== 'undefined') {
			ValotaEngine.WebSocket.url = ValotaEngine.WebSocket.nodes[ValotaEngine.WebSocket.currentNode];
		} else {
			ValotaEngine.WebSocket.url = null;
		}
		if (!ValotaEngine.WebSocket.url) {
			logError("[Engine WebSocket] Empty url, cannot connect. " + ValotaEngine.WebSocket.currentNode);
			ValotaEngine.WebSocket.closedConnections.push({
				opened: null,
				url: ValotaEngine.WebSocket.url,
				from: "empty_url",
				closed: Date.now()
			});
			ValotaEngine.WebSocket.reconnect();
			return;
		}

		if (ValotaEngine.WebSocket.socket !== null && ValotaEngine.WebSocket.socket.readyState !== 3) {
			console.log("[Engine WebSocket] Old connection was open. Called close on it.");
			ValotaEngine.WebSocket.socket.close();
			return; // close initiates a new reconnect
		}

		console.log("[Engine WebSocket] Connecting to " + ValotaEngine.WebSocket.url);

		ValotaEngine.WebSocket.opened = null;

		try {
			ValotaEngine.WebSocket.socket = new WebSocket(ValotaEngine.WebSocket.url);
			ValotaEngine.WebSocket.socket.addEventListener('open', function (ev) {
				//handshake i.e. send display uuid to server
				ValotaEngine.WebSocket.opened = Date.now();
				ValotaEngine.WebSocket.socketStatus = 'connected';
				ValotaEngine.WebSocket.lastConnected_ms = Date.now();
				ValotaEngine.WebSocket.disconnectedConnected_ms = null;

				// clear regular fetch
				if (_REQUEST_TIMER !== null) {
					window.clearTimeout(_REQUEST_TIMER);
					_REQUEST_TIMER = null;
				}

				var req = getRequestData();
				req.action = 'handshake';
				ValotaEngine.WebSocket.send(req);
				setStatus();
				if (ValotaEngine.WebSocket.tryPrimaryNodeTimeout !== null) {
					window.clearTimeout(ValotaEngine.WebSocket.tryPrimaryNodeTimeout);
					ValotaEngine.WebSocket.tryPrimaryNodeTimeout = null;
				}
				if (ValotaEngine.WebSocket.currentNode !== 0) {
					ValotaEngine.WebSocket.tryPrimaryNodeTimeout = window.setTimeout(function () {
						ValotaEngine.WebSocket.tryPrimaryNodeTimeout = null;
						ValotaEngine.WebSocket.currentNode = -1;
						if (!ValotaEngine.WebSocket.close()) {
							ValotaEngine.WebSocket.reconnect();
						}
					}, ValotaEngine.WebSocket.tryPrimaryNode_ms);
				}
				ValotaEngine.WebSocket.startTimesync();

			});

			ValotaEngine.WebSocket.socket.addEventListener('message', ValotaEngine.WebSocket.handleMessage);
			ValotaEngine.WebSocket.socket.addEventListener('close', ValotaEngine.WebSocket.handleClose);
			ValotaEngine.WebSocket.socket.addEventListener('error', ValotaEngine.WebSocket.handleError);
		} catch (ex) {
			console.error(ex);
			logError(JSON.stringify(ex.message));
			ValotaEngine.WebSocket.closedConnections.push({
				opened: null,
				url: ValotaEngine.WebSocket.url,
				from: "threw_exception",
				closed: Date.now()
			});
			ValotaEngine.WebSocket.reconnect();
		}
	},

	send: function (data) {
		console.log("[Engine WebSocket] Sending a message to the server:");
		console.log("[Engine WebSocket]", data);
		try {
			if (ValotaEngine.WebSocket.socket === null) {
				throw 'No socket connection.';
			}
			if (ValotaEngine.WebSocket.socket.readyState !== WebSocket.OPEN) {
				throw 'Socket is not open.';
			}
			data.displayUUID = _displayID;

			ValotaEngine.WebSocket.socket.send(JSON.stringify(data));

		} catch (err) {
			console.error(err);
			logError("[Engine WebSocket] error in send: " + JSON.stringify(err.message));
		}
	},
	handleMessage: function (d) {
		var data = JSON.parse(d.data);
		console.log("[Engine WebSocket] Received a message from the server:");
		console.log("[Engine WebSocket]", data);
		handleDisplayData(data, true);
	},
	handleClose: function (ev) {
		console.log("[Engine WebSocket] Handling closing connection");
		console.log(ev);
		if (ValotaEngine.WebSocket.socketStatus === 'connected') {
			ValotaEngine.WebSocket.currentNode = -1;
		}
		ValotaEngine.WebSocket.closedConnections.push({
			opened: ValotaEngine.WebSocket.opened,
			url: ValotaEngine.WebSocket.url,
			from: ValotaEngine.WebSocket.socketStatus,
			closed: Date.now()
		});
		ValotaEngine.WebSocket.socketStatus = 'closed';
		ValotaEngine.WebSocket.url = null;
		ValotaEngine.WebSocket.disconnectedConnected_ms = Date.now();
		ValotaEngine.WebSocket.reconnect();
		setStatus();
	},
	handleError: function (ev) {
		logError("[Engine WebSocket] connection errored: " + JSON.stringify(ev));
		console.log(ev);
		if (ValotaEngine.WebSocket.socketStatus === 'connected') {
			ValotaEngine.WebSocket.currentNode = -1;
		}
		ValotaEngine.WebSocket.socketStatus = 'errored';
		ValotaEngine.WebSocket.disconnectedConnected_ms = Date.now();
		setStatus();
	},
	reconnect: function () {
		if(!continueRunning){
			// we should not run
			return;
		}
		console.warn("[Engine WebSocket] Try to reconnect");
		if (ValotaEngine.WebSocket.reconnectTimeout) {
			clearTimeout(ValotaEngine.WebSocket.reconnectTimeout);
			ValotaEngine.WebSocket.reconnectTimeout = null;
		}

		if (ValotaEngine.WebSocket.socket !== null && ValotaEngine.WebSocket.socket.readyState !== 3 && ValotaEngine.WebSocket.socket.readyState !== 2) {
			console.log("[Engine WebSocket] No need to reconnect. Socket readyState is " + ValotaEngine.WebSocket.socket.readyState);
			//we have an open or opening connection, ignore reconnect for now
			return;
		}

		if (ValotaEngine.WebSocket.currentNode < 0 && ValotaEngine.WebSocket.nodes.length > 0) {
			// try to connect immediately to primary node
			ValotaEngine.WebSocket.connect();
			return;
		}

		let minute_ms = 60000;

		if (ValotaEngine.WebSocket.currentNode === 0) {

			// how many successful connections have we actually had lately
			var failedCount = 0;
			for (var i = ValotaEngine.WebSocket.closedConnections.length - 1; i >= 0; --i) {
				if (ValotaEngine.WebSocket.closedConnections[i].opened === null && ValotaEngine.WebSocket.closedConnections[i].url === ValotaEngine.WebSocket.nodes[0]) {
					++failedCount;
				} else {
					break;
				}
			}
			console.error('failed', failedCount);
			// After 3 fails, move to next server
			if (failedCount > 3) {
				ValotaEngine.WebSocket.currentNode = 1;
			} else {
				// try the primary node in a bit
				ValotaEngine.WebSocket.reconnectTimeout = window.setTimeout(ValotaEngine.WebSocket.connect, failedCount * minute_ms + Math.floor(Math.random() * minute_ms));
				return;
			}
		} else {
			ValotaEngine.WebSocket.currentNode++;
		}
		if (ValotaEngine.WebSocket.currentNode >= ValotaEngine.WebSocket.nodes.length) {
			// try again after two hours, but for now switch to regular fetching
			logError("[Engine WebSocket] Cannot connect to node servers. Switching back to regular fetch");
			if (_REQUEST_TIMER === null) {
				fetchData();
			}
			if (!ValotaEngine.WebSocket.tryPrimaryNodeTimeout) {
				ValotaEngine.WebSocket.tryPrimaryNodeTimeout = window.setTimeout(function () {
					ValotaEngine.WebSocket.tryPrimaryNodeTimeout = null;
					ValotaEngine.WebSocket.currentNode = 0;
					ValotaEngine.WebSocket.reconnect()
				}, ValotaEngine.WebSocket.tryPrimaryNode_ms);
			}
			return;
		} else {
			ValotaEngine.WebSocket.connect();
			return;
		}

		let now = Date.now();
		// never reached
		// 
		//check if display hasn't received data
		// 
		if (now - ValotaEngine.WebSocket.lastConnected_ms > 10 * minute_ms) {
			// no connection for at least five minutes
			if (now - _FETCH_LAST_SUCCESSFUL_TIME_MS > 10 * minute_ms) {
				// no successful fetch within last ten minutes so do a single fetch
				console.log("[Engine WebSocket] Doing a single fetch because hasn't been able to connect a socket for a long time");
				fetchData(true);
			}
		}
	},
	close: function () {
		if (ValotaEngine.WebSocket.socket !== null) {
			console.warn("[Engine WebSocket] Closing a connection");
			ValotaEngine.WebSocket.socket.close();
			return true;
		} else {
			console.warn("[Engine WebSocket] Connection was not open when closing");
			return false;
		}
	},
	setNewNodeList: function (newNodes) {
		if (!Array.isArray(newNodes)) {
			throw "trying to set a non-array as node list";
		}
		setLocal('node_servers', newNodes);
		if (newNodes.length === 0) {
			ValotaEngine.WebSocket.nodes = [];
			if (ValotaEngine.WebSocket.connected()) {
				// no nodes, close connection
				ValotaEngine.WebSocket.close();
			}
			return;
		}
		ValotaEngine.WebSocket.nodes = newNodes;
		ValotaEngine.WebSocket.currentNode = -1;
		if (newNodes[0] !== ValotaEngine.WebSocket.url && ValotaEngine.WebSocket.connected()) {
			// let's try the new primary node
			ValotaEngine.WebSocket.close();
		}
	},
	noConnectionsUntil: function (connectAgainTime) {
		ValotaEngine.WebSocket.nextConnectTime = connectAgainTime * 1000 + 10000; // +10 seconds
		if (!ValotaEngine.WebSocket.close()) {
			ValotaEngine.WebSocket.reconnect();
		}
	},
	startTimesync: function () {
		window.setTimeout(ValotaEngine.WebSocket.timesync, 5000);
		if (ValotaEngine.WebSocket.timesyncInterval !== null) {
			ValotaEngine.WebSocket.timesyncInterval = window.setInterval(ValotaEngine.WebSocket.timesync, 24 * 60 * 60 * 1000); // daily
		}
	},
	timesync: function () {
		ValotaEngine.WebSocket.timesyncSent = Date.now();
		var req = {};
		req.displayUUID = _displayID;
		req.action = 'timesync';
		ValotaEngine.WebSocket.send(req);
	}
};