/* global _PREVIEW, _displayID, _DISPLAY_UPDATED, _SERVED_APP, _applicationData, _flow, _VALOTA_REST, fetchDataCB, _DATA_REFRESH_TIME */

/**
 *
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2016] Valota Limited
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 *
 *
 * Created by ukah on 29.12.2016.
 */

/* global continueRunning */

/**
 * Fetch data from the server
 *
 * @param {boolean} single_fetch whether to do fetch only once
 * @returns {undefined}
 */
function fetchData(single_fetch) {
	if(_PREVIEW) {
		return;
	}
	if(!continueRunning){
		// don't fetch as another engine is running in the same browser
		return;
	}
	if(typeof single_fetch === 'undefined') {
		single_fetch = false;
	}

	var request = getRequestData();

	request.action ="valota_api/displays/get_data";




	$.ajax({
		type: "POST",
		url: _VALOTA_REST,
		data: request,

		dataType: "json"
	}).done(fetchDataCB)
			.fail(function (xhr, st, err) {

				if (xhr.status === 503) {
					// server under maintenance
					if (xhr.getResponseHeader("Retry-After") !== null &&
							parseInt(xhr.getResponseHeader("Retry-After")) > _DATA_REFRESH_TIME) {
						window.clearTimeout(_REQUEST_TIMER);

						var time = parseInt(xhr.getResponseHeader("Retry-After"));

						if (time > 7200) {
							time = 7200;
						}
						failedFetch("Server under maintenance for " + Math.ceil(time / 60) + " minutes, next try at " + new Date((Date.now() / 1000 + time) * 1000));

						console.log("[FETCH] server 503, next in " + time + "seconds");
						_REQUEST_TIMER = setTimeout(fetchData, time * 1000);
					} else {
						var mes = 'Fetch failed with an error: ' + err + ' (' + st + ')';
						failedFetch(mes);
					}
				} else {
					if(xhr.status===0){
						failedFetch(_('Cannot contact the server'));
					}
					else {
						var mes = 'Fetch failed with an error: ' + xhr.status + " " + err + ' (' + st + ')';
						failedFetch(mes);
					}
				}
			});
	console.log("[FETCH]", request);
	_LAST_TIMER_EPOCH_S = Date.now() / 1000;
	if(!single_fetch) {
		_REQUEST_TIMER = setTimeout(fetchData, _DATA_REFRESH_TIME * 1000);
	}

}