/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

/* global _applicationData, _VALOTA_DEVICE, ValotaAndroid, _VALOTA_EXTENSION, _VALOTA_TIZEN */

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}

ValotaEngine.Video = {
	cacheList: [],
	videoOwners: [], // these are videos per uuid _reported_ by app
	videoRealOwners: [], // these are videos per uuid of app as known to engine
	emergencyActive: false,
	displayIsOff: false,
	fs: null,
	init: function(){
		ValotaEngine.Video.fs = getDeviceClass();
	},
	cacheFile: function (url, cachedCallback) {
		if (ValotaEngine.Video.fs === null) {
			ValotaEngine.Video.fs = getDeviceClass();
		}

		var name = "v_" + JSON.stringify(url).replace(/[^0-9a-z]/gi, '');
		if (typeof ValotaEngine.Video.cacheList[name] !== 'undefined') { // already loading this
			ValotaEngine.Video.cacheList[name].cb.push(cachedCallback);
			return;
		}

		ValotaEngine.Video.cacheList[name] = {};
		ValotaEngine.Video.cacheList[name].url = url;
		ValotaEngine.Video.cacheList[name].cb = [cachedCallback];

		ValotaEngine.Video.fs.cacheFile(name, url, ValotaEngine.Video.fs.fileCachedCallback);
	},
	setMuteVideo: function (uuid, status) {
		if (typeof ValotaEngine.Video.videoOwners[uuid] === 'undefined') {
			ValotaEngine.Video.videoOwners[uuid] = {videoConf: {mute: status ? true : false}, elem: null};
		} else {
			ValotaEngine.Video.videoOwners[uuid].videoConf.mute = status ? true : false;
		}
	},
	getMuteStatus: function (uuid) {
		if (typeof ValotaEngine.Video.videoOwners[uuid] === 'undefined') {
			ValotaEngine.Video.videoOwners[uuid] = {videoConf: {mute: true}, elem: null};
		}
		return ValotaEngine.Video.videoOwners[uuid].videoConf.mute;
	},
	playVideo: function (uuid, url, elem, playStartedCB, endedCB, errorCB, iframeRect) {
		if (ValotaEngine.Video.emergencyActive) {
			window.setTimeout(function () {
				errorCB({message: 'emergency in action'});
			}, 10);
			return;
		} else if (ValotaEngine.Video.displayIsOff) {
			window.setTimeout(function () {
				errorCB({message: 'display off'});
			}, 10);
			return;
		}
		console.log("[ValotaEngine Video] playing", url, "for", uuid, "in", iframeRect);
		ValotaEngine.Video.fs.playVideo(uuid, url, elem, playStartedCB, endedCB, errorCB, iframeRect);
	},
	resizeVideo: function (uuid, elem, iframeRect) {
		ValotaEngine.Video.fs.resizeVideo(uuid, elem, iframeRect);
	},
	stopVideo: function (uuid, elem) {
		ValotaEngine.Video.fs.stopVideo(uuid, elem);
	},
	replayVideo: function (uuid, elem, playStartedCB, errorCB) {
		if (ValotaEngine.Video.emergencyActive) {
			window.setTimeout(function () {
				errorCB({message: 'emergency in action'});
			}, 10);
			return;
		} else if (ValotaEngine.Video.displayIsOff) {
			window.setTimeout(function () {
				errorCB({message: 'display off'});
			}, 10);
			return;
		}
		console.log("[ValotaEngine Video] replaying for", uuid);
		ValotaEngine.Video.fs.replayVideo(uuid, elem, playStartedCB, errorCB);
	},
	emergency: function () {
		ValotaEngine.Video.emergencyActive = true;
		if (ValotaEngine.Video.fs !== null) {
			ValotaEngine.Video.fs.stopVideos();
		}
	},
	emergencyOver: function () {
		ValotaEngine.Video.emergencyActive = false;
	},
	displayOff: function () {
		ValotaEngine.Video.displayIsOff = true;
		if (ValotaEngine.Video.fs !== null) {
			ValotaEngine.Video.fs.stopVideos();
		}
	},
	displayOn: function () {
		ValotaEngine.Video.displayIsOff = false;
	},
	stopVideosByApp: function (uuid) {
		if (!(uuid in ValotaEngine.Video.videoRealOwners)) {
			return; // no videos
		}
		for (var i = ValotaEngine.Video.videoRealOwners[uuid].length - 1; i >= 0; i--) {
			try {
				ValotaEngine.Video.stopVideo(ValotaEngine.Video.videoRealOwners[uuid][i].uuid, ValotaEngine.Video.videoRealOwners[uuid][i].elem);
			} catch (e) {
				if (e !== "not running") {
					throw e;
				}
			}
			ValotaEngine.Video.videoRealOwners[uuid].splice(i, 1);
		}
	},
	stopAllVideos: function () {
		if (ValotaEngine.Video.fs !== null) {
			ValotaEngine.Video.fs.stopVideos();
		}
	}
};

