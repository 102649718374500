/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 * [2013] - [2016] Valota Limited 
 * All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

/* global URL, _VALOTA_LOG_TIZEN, _displayID, _VALOTA_REST, _VALOTA_TIZEN_ORIGIN, Uint8Array, Int8Array */

// TODO enable parallelism
// TODO memory checks

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}
ValotaEngine.Tizen = {
	inited: false,
	callback: null,
	cacheQueue: [],
	files: {},
	videoOwners: [],
	ready: false,
	overlayData: null,
	start: function () {
		console.log("[Engine Tizen] start");
		const claimPin = document.getElementById('claim_pin');
		if (claimPin) {
			claimPin.classList.add('tizen');
		}
		/*	function fixBinary (bin) {
		 var length = bin.length;
		 var buf = new ArrayBuffer(length);
		 var arr = new Uint8Array(buf);
		 for (var i = 0; i < length; i++) {
		 arr[i] = bin.charCodeAt(i);
		 }
		 return buf;
		 }
		 var display=document.getElementById('valota_container');
		 display.innerHTML = (display.innerHTML || '');
		 function log(text) {
		 display.innerHTML += "\n" + text;
		 }  
		 var base64 = 
		 "iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB1klEQVR42n2TzytEURTHv3e8N1joRhZG" + 
		 "zJsoCjsLhcw0jClKWbHwY2GnLGUlIfIP2IjyY2djZTHSMJNQSilFNkz24z0/Ms2MrnvfvMu8mcfZvPvu" + 
		 "Pfdzz/mecwgKLNYKb0cFEgXbRvwV2s2HuWazCbzKA5LvNecDXayBjv9NL7tEpSNgbYzQ5kZmAlSXgsGG" + 
		 "XmS+MjhKxDHgC+quyaPKQtoPYMQPOh5U9H6tBxF+Icy/aolqAqLP5wjWd5r/Ip3YXVILrF4ZRYAxDhCO" + 
		 "J/yCwiMI+/xgjOEzmzIhAio04GeGayIXjQ0wGoAuQ5cmIjh8jNo0GF78QwNhpyvV1O9tdxSSR6PLl51F" + 
		 "nIK3uQ4JJQME4sCxCIRxQbMwPNSjqaobsfskm9l4Ky6jvCzWEnDKU1ayQPe5BbN64vYJ2vwO7CIeLIi3" + 
		 "ciYAoby0M4oNYBrXgdgAbC/MhGCRhyhCZwrcEz1Ib3KKO7f+2I4iFvoVmIxHigGiZHhPIb0bL1bQApFS" + 
		 "9U/AC0ulSXrrhMotka/lQy0Ic08FDeIiAmDvA2HX01W05TopS2j2/H4T6FBVbj4YgV5+AecyLk+Ctvms" + 
		 "QWK8WZZ+Hdf7QGu7fobMuZHyq1DoJLvUqQrfM966EU/qYGwAAAAASUVORK5CYII=";
		 
		 var binary = fixBinary(atob(base64));
		 var blob = new Blob([binary], {type: 'image/png'});
		 var url = URL.createObjectURL(blob);
		 log('Created a png blob of size: ' + blob.size);
		 log('Inserting an img...');
		 var img = document.createElement('img');
		 img.src = url;
		 document.body.appendChild(img);
		 return;
		 */
		if (!ValotaEngine.Tizen.inited) {
			window.addEventListener('message', ValotaEngine.Tizen.onMessage);
			ValotaEngine.Tizen.inited = true;
		}
	},
	clearUUID: function () {
		ValotaEngine.Tizen.appWindow.postMessage({cmd: "clear_uuid"}, ValotaEngine.Tizen.appOrigin);
	},
	saveUUID: function (uuid) {
		ValotaEngine.Tizen.appWindow.postMessage({cmd: "save_uuid", uuid: uuid}, ValotaEngine.Tizen.appOrigin);
	},
	onMessage: function (e) {
		if (window.location.href === 'https://tizen.valota.live/' &&
			e.origin.indexOf(_VALOTA_TIZEN_ORIGIN) === -1) {
			console.warn("[Engine Tizen] unknown origin " + e.origin);
//			return;
		}
		if (typeof e.data !== "object" ||
			!e.data.hasOwnProperty("cmd")) {
			console.warn("[Engine Tizen] syntax error");
			return;
		}
		console.log("[Engine Tizen]", e.data.cmd);

		switch (e.data.cmd) {
			case "initial":
				if (e.data.displayUUID && _displayID !== e.data.displayUUID) {
					// got a different displayUUID from the app than what we are using, switching
					_displayID = e.data.displayUUID;
					setLocal('display', _displayID);
					reloadDisplay(false);
				}
				ValotaEngine.Tizen.appWindow = e.source;
				ValotaEngine.Tizen.appOrigin = e.origin;
				if (e.data.displayUUID === null) {
					ValotaEngine.Tizen.saveUUID(_displayID);
				}
				ValotaEngine.Tizen.appWindow.postMessage({cmd: "get_logs"}, ValotaEngine.Tizen.appOrigin);
				var cacheRetrievalObj = ValotaEngine.Tizen.cacheQueue.shift();
				if (typeof cacheRetrievalObj !== "undefined") {
					ValotaEngine.Tizen.cacheFile(cacheRetrievalObj.name, cacheRetrievalObj.url, cacheRetrievalObj.callback);
				}
				ValotaEngine.Tizen.ready = true;
				break;
			case "device_id":
				console.log("[Engine Tizen] device id", e.data.deviceID);
				sendMyDeviceID(e.data.deviceID);
				break;
			case "bt_address":
				console.log("[Engine Tizen] BT address", e.data.btAddress);
				sendMyBTAddress(e.data.btAddress);
				break;
			case "loaded":
				if (e.data.filename !== ValotaEngine.Tizen.name ||
					e.data.url !== ValotaEngine.Tizen.url) {
					console.error("[Engine Tizen] bad coding", e.data);
					if (ValotaEngine.Tizen.callback !== null) {
						ValotaEngine.Tizen.callback(e.data.filename, e.data.url, '', {status: "error", message: _('Bad coding')});
					}
					ValotaEngine.Tizen.callback = null;
					ValotaEngine.Tizen.name = null;
					ValotaEngine.Tizen.url = null;
					return;
				}
				ValotaEngine.Tizen.files[e.data.filename].localURL = e.data.localURL;
				if (ValotaEngine.Tizen.callback !== null) {
					ValotaEngine.Tizen.callback(e.data.filename, e.data.url, null, {status: 'ok'});
				}
				ValotaEngine.Tizen.callback = null;
				ValotaEngine.Tizen.name = null;
				ValotaEngine.Tizen.url = null;
				var cacheRetrievalObj = ValotaEngine.Tizen.cacheQueue.shift();
				if (typeof cacheRetrievalObj !== "undefined") {
					ValotaEngine.Tizen.cacheFile(cacheRetrievalObj.name, cacheRetrievalObj.url, cacheRetrievalObj.callback);
				}
				break;
			case "error":
				if (ValotaEngine.Tizen.callback !== null) {
					ValotaEngine.Tizen.callback(e.data.filename, e.data.url, '', {status: "error", message: _("filesystem error: " + e.data.message)});
				}
				ValotaEngine.Tizen.callback = null;
				ValotaEngine.Tizen.name = null;
				ValotaEngine.Tizen.url = null;
				break;
			case "logs":
				// TODO log the origin to change the origin check
				console.log("[Engine Tizen] Log from the Tizen app:", e.data);
				if (typeof _VALOTA_LOG_TIZEN !== 'undefined' && _VALOTA_LOG_TIZEN === true) {
					if (e.data.data.length > 0) {
						var request = {action: "valota_api/log/chrome_os_log", appUUID: "ValotaApp", displayUUID: _displayID, message: JSON.stringify(e.data.data)};
						$.ajax({
							type: "POST",
							url: _VALOTA_REST,
							data: request,
							dataType: "json"
						}).done(function () {})
							.fail(function (xhr, st, err) {
								var mes = 'Failed to send log: ' + err + ' (' + st + ')';
								console.error("[Engine Tizen] " + mes);
							});
					}
				}
				break;
			case "busy":
				console.error("[Engine Tizen] bad coding"); // shouldn't bother when something is going on
				if (ValotaEngine.Tizen.callback !== null) {
					ValotaEngine.Tizen.callback(e.data.name, e.data.url, '', {status: "error", message: _('Bad coding')});
				}
				ValotaEngine.Tizen.callback = null;
				ValotaEngine.Tizen.name = null;
				ValotaEngine.Tizen.url = null;
				break;
			case "videoStarted":
				if (e.data.url !== ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].url) {
					console.error("[Engine Tizen] wrong video playing?", e.data, ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].url);
				} else {
					if (typeof ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].videoStartedPlayingCB === 'function') {
						ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].videoStartedPlayingCB(e.data.duration);
					} else {
						console.error("[Engine Tizen] video playing, no callback");
					}
				}
				break;
			case "videoEnded":
				if (e.data.url !== ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].url) {
					console.error("[Engine Tizen] wrong video ended?", e.data.url, ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].url);
				} else {
					if (typeof ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].videoEndedCB === 'function') {
						ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].videoEndedCB();
					} else {
						console.error("[Engine Tizen] video ended, no callback");
					}
				}
				break;
			case "videoErrored":
				if (e.data.url !== ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].url) {
					console.error("[Engine Tizen] wrong video errored?");
				} else {
					if (typeof ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].videoErrorCB === 'function') {
						console.error("[Engine Tizen] video errored", e.data);
						ValotaEngine.Tizen.videoOwners[e.data.ownerUuid].videoErrorCB(e.data);
					} else {
						console.error("[Engine Tizen] video errored, no callback", e.data);
					}
				}
				break;

			case "screenshot":

				if (typeof ValotaEngine.Tizen.screenshotCallback === 'function') {
					ValotaEngine.Tizen.screenshotCallback(e.data.url);
				}
				break;
			case "overlay_crash":
				logError(e.data.message, e.data.uuid, e.data.severity);
				break;
			default:
				video_erro
				console.warn("[Engine Tizen] unknown command " + e.data.cmd);
		}
	},
	fileCachedCallback: function (name, url, unused, status) {
		console.log("[Engine Tizen] file cached", url);
		for (var i = 0; i < ValotaEngine.Video.cacheList[name].cb.length; i++) {
			ValotaEngine.Video.cacheList[name].cb[i](url, status);
		}
		delete ValotaEngine.Video.cacheList[name];
	},
	cacheFile: function (name, url, callback) {
		if (typeof ValotaEngine.Tizen.appWindow === "undefined") {
			var tempObj = {};
			tempObj.name = name;
			tempObj.url = url;
			tempObj.callback = callback;
			ValotaEngine.Tizen.cacheQueue.push(tempObj);
			return true;
		}
		if (typeof ValotaEngine.Tizen.files[name] !== 'undefined') {
			if (ValotaEngine.Tizen.files[name].inMemory === true) {
				window.setTimeout(function () {
					callback(name, url, '', {status: 'ok'});
				}, 10);
				return true;
			} else if (ValotaEngine.Tizen.files[name].loading === true) {
				// already loading this to memory
				return true;
			}
		}
		if (ValotaEngine.Tizen.callback !== null) {
			// cacheing something already, put this to queue
			var tempObj = {};
			tempObj.name = name;
			tempObj.url = url;
			tempObj.callback = callback;
			ValotaEngine.Tizen.cacheQueue.push(tempObj);
			return true;
		}
		ValotaEngine.Tizen.callback = callback;
		ValotaEngine.Tizen.name = name;
		ValotaEngine.Tizen.url = url;
		ValotaEngine.Tizen.files[name] = {};
		ValotaEngine.Tizen.files[name].inMemory = false;
		ValotaEngine.Tizen.files[name].url = null;
		ValotaEngine.Tizen.appWindow.postMessage({cmd: "load", filename: name, url: url}, ValotaEngine.Tizen.appOrigin);
		return true;
	},
	playVideo: function (uuid, url, elem, playStartedCB, endedCB, errorCB, iframeRect) {
		var name = "v_" + JSON.stringify(url).replace(/[^0-9a-z]/gi, '');
		if ((!(name in ValotaEngine.Tizen.files)) || ValotaEngine.Tizen.files[name].loading) {
			throw "trying to play an uncached video";
		}
		if (!(uuid in ValotaEngine.Tizen.videoOwners)) {
			ValotaEngine.Tizen.videoOwners[uuid] = {elem: null, videoStartedPlayingCB: null, videoEndedCB: null, videoErrorCB: null};
		}
		if (ValotaEngine.Tizen.videoOwners[uuid].elem !== null) {
			if (ValotaEngine.Tizen.videoOwners[uuid].elem === elem) {
				throw "bad coding, use replayVideo to replay a video :O";
			}
			throw "bad coding, a video set when trying to play video. stop first";
		}

//		console.log(elem.getBoundingClientRect());
		var rect = elem.getBoundingClientRect();
		console.log(rect);

		var coordObj = {width: rect.width, height: rect.height, left: (rect.left + iframeRect.left), top: (rect.top + iframeRect.top)};
		ValotaEngine.Tizen.videoOwners[uuid].elem = elem;
		ValotaEngine.Tizen.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.Tizen.videoOwners[uuid].videoEndedCB = endedCB;
		ValotaEngine.Tizen.videoOwners[uuid].videoErrorCB = errorCB;
		ValotaEngine.Tizen.videoOwners[uuid].url = url;
		ValotaEngine.Tizen.appWindow.postMessage({cmd: "play", filename: name, ownerUuid: uuid, url: url, rect: coordObj, mute: ValotaEngine.Video.getMuteStatus(uuid)}, ValotaEngine.Tizen.appOrigin);
	},
	resizeVideo: function (uuid, elem, iframeRect) {
		if (ValotaEngine.Tizen.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to resize video when something else is playing";
		}
		var rect = elem.getBoundingClientRect();
		var coordObj = {width: rect.width, height: rect.height, left: (rect.left + iframeRect.left), top: (rect.top + iframeRect.top)};
		ValotaEngine.Tizen.appWindow.postMessage({cmd: "resize", ownerUuid: uuid, url: ValotaEngine.Tizen.videoOwners[uuid].url, rect: coordObj}, ValotaEngine.Tizen.appOrigin);
	},
	stopVideo: function (uuid, elem) {
		if (typeof ValotaEngine.Tizen.videoOwners[uuid] === 'undefined') {
			throw "unknown uuid"; // never played anything
		}
		if (ValotaEngine.Tizen.videoOwners[uuid].elem === null) {
			throw "not running"; // played but not running
		}
		if (ValotaEngine.Tizen.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to stop video when something else is playing";
		}
		ValotaEngine.Tizen.appWindow.postMessage({cmd: "stop", ownerUuid: uuid}, ValotaEngine.Tizen.appOrigin);
		ValotaEngine.Tizen.videoOwners[uuid].videoEndedCB = null;
		ValotaEngine.Tizen.videoOwners[uuid].videoErrorCB = null;
		ValotaEngine.Tizen.videoOwners[uuid].videoStartedPlayingCB = null;
		ValotaEngine.Tizen.videoOwners[uuid].elem = null;
		ValotaEngine.Tizen.videoOwners[uuid].url = null;
	},
	replayVideo: function (uuid, elem, playStartedCB, errorCB) {
		if (typeof ValotaEngine.Tizen.videoOwners[uuid] === 'undefined') {
			throw "unknown uuid"; // never played anything
		}
		if (ValotaEngine.Tizen.videoOwners[uuid].elem !== elem) {
			throw "bad coding, trying to replay video that is not playing";
		}
		ValotaEngine.Tizen.appWindow.postMessage({cmd: "replay", ownerUuid: uuid, url: ValotaEngine.Tizen.videoOwners[uuid].url}, ValotaEngine.Tizen.appOrigin);
		ValotaEngine.Tizen.videoOwners[uuid].videoStartedPlayingCB = playStartedCB;
		ValotaEngine.Tizen.videoOwners[uuid].videoErrorCB = errorCB;
	},
	playOverlayApp: function (appData) {
		if (typeof ValotaEngine.Tizen.appWindow === 'undefined') {
			window.setTimeout(function () {
				ValotaEngine.Tizen.playOverlayApp(appData);
			}, 100);
			return;
		}
		ValotaEngine.Tizen.overlayData = appData;
		if (!ValotaEngine.Tizen.emergencyActive) {
			ValotaEngine.Tizen.appWindow.postMessage({cmd: "overlay_new_app", appData: appData}, ValotaEngine.Tizen.appOrigin);
			ValotaEngine.Tizen.overlayPlaying = true;
		}
	},
	emergency: function () {
		if (!ValotaEngine.Tizen.emergencyActive) {
			console.log("[Engine Tizen] overlay emergency starts, currently " + (ValotaEngine.Tizen.overlayPlaying ? "playing" : "stopped"));
			if (ValotaEngine.Tizen.overlayPlaying) {
				ValotaEngine.Tizen.appWindow.postMessage({cmd: "overlay_stop"}, ValotaEngine.Tizen.appOrigin);
				ValotaEngine.Tizen.overlayPlaying = false;
			}
			ValotaEngine.Tizen.emergencyActive = true;
		}
	},
	emergencyOver: function () {
		if (ValotaEngine.Tizen.emergencyActive) {
			console.log("[Engine Tizen] overlay emergency over, " + (ValotaEngine.Tizen.overlayData === null ? "will do nothing" : "will start"));
			console.log(ValotaEngine.Tizen.overlayData);
			if ((!ValotaEngine.Tizen.overlayPlaying) && ValotaEngine.Tizen.overlayData !== null) {
				ValotaEngine.Tizen.appWindow.postMessage({cmd: "overlay_new_app", appData: ValotaEngine.Tizen.overlayData}, ValotaEngine.Tizen.appOrigin);
				ValotaEngine.Tizen.overlayPlaying = true;
			}
			ValotaEngine.Tizen.emergencyActive = false;
		}
	},
	overlayAppChangedCustoms: function (customs) {
		if (ValotaEngine.Tizen.overlayData !== null) {
			ValotaEngine.Tizen.overlayData.customs = customs;
			if (ValotaEngine.Tizen.overlayPlaying) {
				ValotaEngine.Tizen.appWindow.postMessage({cmd: "overlay_new_customs", customs: customs}, ValotaEngine.Tizen.appOrigin);
			}
		}
	},
	overlayAppChangedPalette: function (palette) {
		if (ValotaEngine.Tizen.overlayData !== null) {
			ValotaEngine.Tizen.overlayData.palette = palette;
			if (ValotaEngine.Tizen.overlayPlaying) {
				ValotaEngine.Tizen.appWindow.postMessage({cmd: "overlay_new_palette", palette: palette}, ValotaEngine.Tizen.appOrigin);
			}
		}
	},
	overlayAppChangedSource: function (source) {
		if (ValotaEngine.Tizen.overlayData !== null) {
			ValotaEngine.Tizen.overlayData.source = source;
			if (ValotaEngine.Tizen.overlayPlaying) {
				ValotaEngine.Tizen.appWindow.postMessage({cmd: "overlay_new_source", source: source}, ValotaEngine.Tizen.appOrigin);
			}
		}
	},
	stopOverlayApp: function () {
		ValotaEngine.Tizen.appWindow.postMessage({cmd: "overlay_stop"}, ValotaEngine.Tizen.appOrigin);
		ValotaEngine.Tizen.overlayPlaying = false;
		ValotaEngine.Tizen.overlayData = null;
	},
	stopVideos: function () {
		for (var uuid in ValotaEngine.Tizen.videoOwners) {
			if (ValotaEngine.Tizen.videoOwners[uuid].elem !== null) {
				try {
					ValotaEngine.Tizen.videoOwners[uuid].videoEndedCB();
					ValotaEngine.Tizen.stopVideo(uuid, ValotaEngine.Tizen.videoOwners[uuid].elem);
				} catch (e) {
					// do nothing now
				}
			}
		}
	}
};