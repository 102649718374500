/* global ValotaEngine, _applicationData, _VALOTA_REST, currentStory, runPendingFunctions, _VALOTA_DEVICE */

/**
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2016] Valota Limited
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 *
 *
 * Created by ukah on 29.12.2016.
 */

/* global continueRunning */

/**
 * Crunch our data
 *
 * @param {json} data contains response of the server
 * @returns {undefined}
 */
function fetchDataCB(data) {
	if(!continueRunning){
		// we are not the first instance
		return;
	}
	console.log("[FetchCB]");
	if (data.status !== "ok") {
		var mes = data.status_messages;
		failedFetch(mes);
	} else {

		_successfulFetch();
		if (typeof _SERVED_APP !== "undefined" && _SERVED_APP && !staticServe()) {
			_SERVED_APP = null;
		}

		if (typeof data.response !== 'undefined') {

			var re = data.response;

			var ret = handleDisplayData(re, false);
			if(ret === false) {
				return;
			}

		} else {
			var emergency_elem = document.getElementById('valota_emergency');
			emergency_elem.html = "";
			emergency_elem.style.display = "none";
		}

		if (!hasApps()) {
			showWindow('waiting_for_content');
		}
	}



}